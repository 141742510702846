$(function () {
    var hamburger = document.querySelector('#menu-btn');

    hamburger.addEventListener('click', function() {
        hamburger.classList.toggle('is-active');
    });
    
    $('#menu-btn').click(function() {
        $('.main-menu').show();
        setTimeout(function (){
            $('.main-menu').toggleClass('overlay-open');
        }, 50);
    });

    $(document).on('pjax:success', function () {
        $('.main-menu').hide().removeClass('overlay-open');
        $('.menu-btn').removeClass('is-active');
    });

});
