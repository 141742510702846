$(function() {
    // GET THIS HAPPENING PROPERLY WITH PJAX
    // $(document).ready(function() {
    //
    // 	setTimeout(function(){
    // 		$('body').addClass('loaded');
    // 	}, 3000);
    //
    // });
});
