window.App.initCallbacks.push(function () {
    $('.home-carousel').slick({
        dots: true,
        fade: true,
        prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="Previous" role="button"><i class="icon-arrow-left"></i></button>',
        nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" aria-label="Next" role="button"><i class="icon-arrow-right"></i></button>'
    });
    $('.home-carousel video').each(function () {
        this.play();
    });
});
