window.App = {
    initCallbacks: [],
    init: function () {
        $.each(window.App.initCallbacks, function (index, callback) {
            callback();
        });
    },
    cleanupCallbacks: [],
    cleanup: function () {
        $.each(window.App.cleanupCallbacks, function (index, callback) {
            callback();
        });
    }
};

$(document).ready(window.App.init);
