window.App.initCallbacks.push(function() {

    $('.btn-play-song').on('click touchstart', function (e) {
        var musikp = $('body').data('plugin_musikp');
        var playButton = $(this);

        e.preventDefault();
        e.stopImmediatePropagation();

        var delay = 0;
        var player = playButton.closest('.record-player');
        var otherPlayers = $('.record-player').not(player);

        if (otherPlayers.is('.play')) {
            delay = 400;
            otherPlayers.removeClass('play').removeClass('pause');
            musikp.pause();
        }

        setTimeout(function () {
            if (player.is('.pause')) {
                player.removeClass('pause');
                musikp.play(playButton.attr('data-musikp'));
            } else if(player.is('.play')) {
                player.addClass('pause');
                musikp.pause();
            } else {
                setTimeout(function () {
                    musikp.play(playButton.attr('data-musikp'));
                }, 500);
            }

            player.addClass('play');
        }, delay);
    });
});
