window.App.initCallbacks.push(function() {

    if($('.pg-header').length === 0) {
        return;
    }

    // INITIATING SCOLLMAGIC
    var controller = new ScrollMagic.Controller();

    //ANIMATING HEADER CONTENT
    var hero = new TimelineMax();

    hero
        .to($('.pg-header .wrapper, .supporting-text'), 0.2, {autoAlpha: 0, ease:Power1.easeNone})
        .to($('.pg-header .overlay'), 0.7, {autoAlpha: 0.1, ease:Power1.easeNone}, 0);

    var introScene = new ScrollMagic.Scene({
        triggerElement: '.pg-header',
        triggerHook: 0,
        duration: "100%"
    })
    .setTween(hero)
    .addTo(controller);


    // ANIMATING THE LINE BREAKS
    $('.line-break').each(function() {
        var animateLineBreak = new ScrollMagic.Scene({
            triggerElement: this,
            reverse: false
        })
        .setClassToggle(this, 'draw')
        .addTo(controller);
    });

    window.App.cleanupCallbacks.push(function () {
        controller.destroy();
    });

    // // ANIMATING THE ALBUM COVERS
    // var animateAlbareImg = new ScrollMagic.Scene({
    //     triggerElement: '.albare-vitals .text-content',
    //     triggerHook: "onEnter",
    //     duration: "200%"
    // })
    // .setTween(".albare-vitals .supporting-img", {y:"90%", ease: Linear.easeNone})
    // .addTo(controller);

});
