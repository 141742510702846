window.App.initCallbacks.push(function() {

    if($('.home-banner').length === 0) {
        return;
    }

    // INITIATING SCOLLMAGIC
    var controller = new ScrollMagic.Controller();

    var homeHero = new TimelineMax();

    homeHero
        .to($('.home-banner .content, .scroll-down'), 0.9, {autoAlpha: 0.3, ease:Power1.easeNone})
        .to($('.home-banner video'), 1, {autoAlpha: 0.3, ease:Power1.easeNone}, 0);

    var introScene = new ScrollMagic.Scene({
        triggerElement: '.home-banner',
        triggerHook: 0,
        duration: "100%"
    })
    .setTween(homeHero)
    .addTo(controller);

    //ANIMATING THE LIVE BREAKS ON THE HOME PAGE
    $('.line-break').each(function() {
        var animateLineBreak = new ScrollMagic.Scene({
            triggerElement: this,
            reverse: false
        })
        .setClassToggle(this, 'draw')
        .addTo(controller);
    });

    // Enable ScrollMagic only for desktop, disable on touch and mobile devices
	if (!Modernizr.touch) {

        var animateAlbareImg = new ScrollMagic.Scene({
            triggerElement: '.albare-vitals .text-content',
            triggerHook: "onEnter",
            duration: "200%"
        })
        .setTween(".albare-vitals .supporting-img", {y:"90%", ease: Linear.easeNone})
        .addTo(controller);

    }

    window.App.cleanupCallbacks.push(function () {
        controller.destroy();
    });
});
