$(function() {
    $.get('/api/tracks', function (tracks) {
        $('body').musikp({
            playlist: tracks,
            autoPlay: false,
            mode: 'gui', // gui, api, auto
            debug: true,
            playBt: '<i class="fa fa-play" aria-hidden="true"></i>',
            pauseBt: '<i class="fa fa-pause" aria-hidden="true"></i>',
            prevBt: '<i class="fa fa-step-backward" aria-hidden="true"></i>',
            nextBt: '<i class="fa fa-step-forward" aria-hidden="true"></i>',
            expandBt: '<i class="fa fa-chevron-up" aria-hidden="true"></i>',
            onPause: function (trackId) {
                $('.record-player').removeClass('play').removeClass('pause');
                $('[data-musikp="' + trackId + '"]').closest('.record-player').addClass('play').addClass('pause');

                $('[data-musikp]').removeClass('playing');
            },
            onPlay: function (trackId) {
                $('.record-player').removeClass('play').removeClass('pause');
                $('[data-musikp="' + trackId + '"]').closest('.record-player').addClass('play');

                $('[data-musikp]').removeClass('playing');
                $('[data-musikp="' + trackId + '"]').addClass('playing');
            }
        });
    });
    
});
