$(function() {
    // autoPlayYouTubeModal();
    //
    // function autoPlayYouTubeModal() {
    //     var trigger = $('body').find('[data-toggle="modal"]');
    //     trigger.click(function() {
    //         var theModal = $(this).data('target'),
    //             videoSRC = $(this).attr('data-video'),
    //             videoSRCauto = videoSRC + '?autoplay=1';
    //         $(theModal + 'iframe').attr('src', videoSRCauto);
    //         $(theModal + 'button.close').click(function() {
    //             $(theModal + 'iframe').attr('src', videoSRC);
    //         });
    //     });
    // }
    $(document).on('click', '[data-toggle="video-lightbox"]', function(event) {
        event.preventDefault();
        $(this).ekkoLightbox();
    });
});
