window.App.initCallbacks.push(function() {

    if($("#gmap").length === 0) {
        return;
    }

    var map = new GMaps({
        el: "#gmap",
        scrollwheel: false,
        zoom: 4,
        lat: -12.043333,
        lng: -77.028333
    });

    var fitAllMarkersOnMap = function () {
        var markers = [];//some array
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < map.markers.length; i++) {
            var latLng = map.markers[i].getPosition();
            var lat = latLng.lat();
            var lng = latLng.lng();
            bounds.extend(new google.maps.LatLng(lat, lng));
        }

        map.fitBounds(bounds);
    };

    var loadMarkers = function(shows) {
        map.removeMarkers();
        $.each(shows, function (index, value) {
            map.addMarker({
                icon: '/img/ui/map-marker.png',
                lat: value.lat,
                lng: value.lng,
                title: value.name,
                infoWindow: {
                    content: '<div class="gmap-info-window" data-lat="' + value.lat + '" data-lng="' + value.lng + '"><h3><span class="icon-map-marker"></span>' + value.name + '</h3><p>' + value.address + '</p><p>' + value.date + '</p><a href="#_" class="more-info" data-show-id="' + value.id + '"><span class="fa fa-info-circle"></span> More info</a></div>'
                }
            });
        });
    };


    $.ajax({
        url: "/load-shows",
        context: document.body
    }).done(function (data) {
        var findShow = function (id) {
            for (var key in data) {
                if (data[key].id == id) {
                    return data[key];
                }
            }

            throw 'Cant find show';
        };

        loadMarkers(data);

        fitAllMarkersOnMap();

        // LOCATION MAP
        $(document).on('click', '.more-info', function (e) {
            var showId = $(this).attr('data-show-id');

            var lat = findShow(showId).lat;
            var lng = findShow(showId).lng;

            e.preventDefault();

            $('.show-info').removeClass('show');
            $('.interactive-map').addClass('display-specific-show');
            $('.list-of-upcoming-shows').addClass('hide');
            $('.show-info[data-show-id="' + showId + '"]').addClass('show');


            setTimeout(function () {
                map.refresh();
                map.fitBounds(new google.maps.LatLngBounds());
                map.setZoom(17);
                map.setCenter(lat, lng);
                map.hideInfoWindows();
            }, 1200);
        });

        // CLICK BACK ON THE MAP
        $(document).on('click', '.close-show-details', function (e) {
            e.preventDefault();
            $('.show-info').removeClass('show');
            $('.interactive-map').removeClass('display-specific-show');
            $('.list-of-upcoming-shows').removeClass('hide');
            setTimeout(function () {
                map.refresh();
                fitAllMarkersOnMap();
            }, 1200);
        });
    });

});
