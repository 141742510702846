// window.App.initCallbacks.push(function() {
//     var text = $('.pg-header > .wrapper');
//     var range = 200;
//
//     if(text.length === 0) {
//         return;
//     }
//
//     $(window).on('scroll', function() {
//         var scrollTop = $(this).scrollTop();
//         var offset = text.offset().top;
//         var height = text.outerHeight();
//         offset = offset + height / 2;
//         var calc = 1 - (scrollTop - offset + range) / range;
//
//         text.css({
//             'opacity': calc
//         });
//
//         if(calc > '1') {
//             text.css({
//                 'opacity': 1
//             });
//         } else if (calc < '0') {
//             text.css({
//                 'opacity': 0
//             });
//         }
//     });
// });
