$(function () {
    $(document).pjax('a', '#pjax-container', {
        timeout: 0
    });

    $(document).on('pjax:start', function () {
        $('body').addClass('loading');
    });

    $(document).on('pjax:complete', function () {
        $('body').removeClass('loading');
        var metadata = $($('#pjax-container').children('meta[name="metadata"]').attr('content'));
        var title = metadata.filter('title').text();
        document.title = title;
        metadata.contents().appendTo('head');
        metadata.remove();
    });

    $(document).on('pjax:success', window.App.init);
    $(document).on('pjax:start', window.App.cleanup);

    $('body').removeClass('loading');
});
